<template>
  <div class="container container-table">
    <div class="columns is-centered is-multiline">
      <div class="container column is-8">
        <div style="max-width:18em; margin: 0 auto;">
          <a href="/" class="edshedlogo">
            <img src="/img/logo-edshed.png" alt="Logo" class="img-responsive center-block">
          </a>
        </div>
        <div class="center-block" style="width: fit-content; text-align: center">
          <img src="/img/logo.png" class="inline-block sublogo">
          <img v-if="$route.query.locale == 'en_US'" src="/img/math-shed-logo-dark.png" class="inline-block sublogo mathslogo">
          <img v-else src="/img/maths-shed-logo-dark.png" class="inline-block sublogo mathslogo">
        </div>
      </div>
      <!-- <img src="/img/Bee.svg" alt="Logo" class="img-responsive center-block logo"> -->
      <div class="column is-8">
        <div class="box box-info" style="color:black">
          <div class="box-header content with-border">
            <div class="text-center">
              <h1>{{ this.$route.name.toUpperCase() }}</h1>
            </div>
          </div>
          <div class="box-body">
            <!-- login form -->

            <!-- errors -->
            <!-- <div class="text-center col-md-12">
                  <router-link to="/logout">Logout</router-link>
                </div> -->
            <p>Unsubscribe to stop receiving updates and offers from us by email.</p>
            <p>&nbsp;</p>
            <button v-if="!clicked" class="button is-warning is-rounded has-text-weight-bold" @click="unsubscribe">
              UNSUBSCRIBE
            </button>
            <a v-else class="button is-link is-rounded has-text-weight-bold" href="https://edshed.com">
              GO TO HOME
            </a>
            <p>&nbsp;</p>
            <div v-if="response" class="alert alert-info">
              <p>{{ response }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
        &nbsp;
    </div>
    <div class="row">
      <div class="container">
        <div class="text-center col-md-4 col-sm-offset-4">
          <p style="font-size:14px; color: #4a4a4a;">
            Copyright &copy; {{ new Date().getFullYear() }} Education Shed Ltd.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { request } from '@/edshed-common/api'
import { ToastProgrammatic as Toast } from 'buefy'

export default {
  name: 'VerifyView',
  data (router) {
    return {
      response: '',
      clicked: false
    }
  },
  mounted () {
    this.$nextTick(() => {
    })
  },
  methods: {
    unsubscribe () {
      if (this.$router.history.current.query && this.$router.history.current.query.email) {
        const email = this.$router.history.current.query.email
        const verifyemail = this.$router.history.current.query.verifyemail
        console.log(email)
        // send request
        console.log('-- email: ' + email)
        console.log('-- verifyemail: ' + verifyemail)
        request('POST', 'users/me/marketing/unsubscribe', { email, verifyemail })
          .then((response) => {
            const data = response.data
            console.log(data)

            if (data.error) {
              this.response = data.error
            } else {
              this.response = 'Unsubscribe successful'
              this.clicked = true
              Toast.open({
                message: 'Unsubscribe successful',
                position: 'is-bottom',
                type: 'is-success'
              })
            }
          })
          .catch((error) => {
            console.log(error)
            if (error.response && error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
          })
      } else {
        this.$router.push('/logout')
      }
    }
  }
}
</script>
